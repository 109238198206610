import isEmpty from "lodash/isEmpty";
import { partStatusWithSpecialOrderParts } from "../../../constants/app.constants";
import { isPartInstock } from "../../page-wrapper/utils/data-util";

export const isApprovedAllPartsDisabled = parts => {
  let allApproved = true;
  let allBackordered = true;
  let hasOtherStatus = false;

  // Loop through each part in the line to evaluate their status
  parts?.forEach(p => {
    if (p.lifecycleState !== partStatusWithSpecialOrderParts.APPROVED) {
      allApproved = false;
    }

    if (p.lifecycleState !== partStatusWithSpecialOrderParts.BACKORDERED) {
      allBackordered = false;
    }

    if (
      p.lifecycleState !== partStatusWithSpecialOrderParts.APPROVED &&
      p.lifecycleState !== partStatusWithSpecialOrderParts.BACKORDERED
    ) {
      hasOtherStatus = true;
    }
  });

  // Determine the state of the 'Approve All Parts' button
  if (allApproved || allBackordered) {
    // Disable the Approve All Parts button
    return true;
  } else if (hasOtherStatus) {
    // Enable the Approve All Parts button
    return false;
  }

  return true;
};

export const getPartsToApprove = parts => {
  return parts?.filter(p => {
    if (p.isCorePart || p.coreReturnId) return false;
    return (
      (isEmpty(p.lifecycleState) &&
        isPartInstock(p.quantity, p.quantityAvailable) &&
        p?.unitCost) ||
      (p.lifecycleState === partStatusWithSpecialOrderParts.OPEN && p?.unitCost)
    );
  });
};

export const allPartsInEligibleForApproval = parts => {
  for (const p of parts) {
    if (
      (p.lifecycleState === partStatusWithSpecialOrderParts.OPEN &&
        p?.unitCost) ||
      (isEmpty(p.lifecycleState) &&
        isPartInstock(p.quantity, p.quantityAvailable) &&
        p?.unitCost)
    ) {
      return false;
    }
  }

  return true;
};
